<script>
import http from '@/app-http'
import { mapActions, mapGetters } from 'vuex'
import StatusColor from '@/views/pages/directories/status-color.vue';

export default {
 
  components: {
    StatusColor
  },
  data() {
    return {
      isBusy: true,
      report: null,
      statusList: [],
      from: null,
      till: null,
      donor: null,
      donors: [],
      regions: null,
      selectedRegion: null
    };
  },
  created(){
    this.init();
    //this.load();
  },
  computed :{
    ...mapGetters('auth', {
      token: 'token'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    requestEntity() {
      return this.directories.find(x => x.id === '1ee317c47fb34cb3888f0788ccf19d0b');
    }
  },
  methods: {
    ...mapActions('auth', {
      'refreshToken': 'refresh'
    }),
    toggleStatus(status) {
      if(this.statusList.find(x => x.id === status.id)) {
        this.statusList = this.statusList.filter(x => x.id !== status.id);
      } else {
        this.statusList = [...this.statusList, status];
      }
    },
    async init(){
      const donorsResp = await http.post(`directories/e95e7fce8dcf4781807fcef04495cb1e/items/search`, {
        skip:0,
        take:1000,
        orderBy: {
          field: '942d8c30-f5ed-11ec-ba39-9dbd873ea88a', //Name,
          asc: true
        }
      });
      this.donors = donorsResp.data.items;
      this.isBusy = false;
    },
    fileUrl(url) {
      return `${process.env.VUE_APP_URL}${url}?token=${this.token}`;
    },
    async load() {
      this.isBusy = true;
      const q  ={
        statusList: this.statusList.map(x => x.id),
        donor: this.donor,
        from: this.from,
        till: this.till,
        selectedRegion: this.selectedRegion
      };
      const response = await http.get('reports/process-1',  {
        params: q
      });
      await this.refreshToken();
      this.report = response.data;
      if(!this.selectedRegion) {
        const o =  Object.keys(this.report.report1.countByRegions).filter(x => x);
        o.sort((a, b) => a.localeCompare(b));
        this.regions = o;
      
      }
      this.isBusy = false;
   }
  }
};
</script>

<template>
   <div class="card">
    <div class="card-body position-relative" v-if="requestEntity">
      <h4 class="card-title mb-4">Кількість наданої допомоги</h4>
      <div class="row g-3 align-items-center">
        <div class="mb-4 col-auto">
          <small><label class="m-0">Донор</label></small>
          <select class="form-control" v-model="donor">
            <option :value="null">Все</option>
            <option v-for="d in donors" :key="d.id" :value="d.id">{{d.display}}</option>
          </select>
        </div>
        <div class="mb-4  col-auto">
          <small><label class="m-0">Статус</label></small>
          <div class="dropdown ">
            <button
              :title="statusList.map(x => x.name).join(', ')"
              type="button"
              class="btn bg-white waves-effect waves-light text-nowrap text-truncate"
          
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style="position:relative; padding-right: 30px; min-width: 230px; max-width: 300px; border: 1px solid #e2e5e8 !important; text-align: left;"
            >
              <span v-if="statusList.length === 0 || statusList.length === requestEntity.statusList.length">{{$t('common.allStatuses')}}</span>
              <template v-if="statusList.length > 0 && statusList.length < requestEntity.statusList.length">
                <span class=" me-3 text-nowrap text-truncate" v-for="st in statusList" :key="st.id">
                  <status-color :color="st.color"></status-color>{{st.name}}
                </span>
              </template>

              <span style="position: absolute; right:5px; top:10px">
                <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
              </span>
            
            </button>
            <div class="dropdown-menu dropdown-menu-end pt-2">
              <template v-for="status in requestEntity.statusList || []" :key="status.id">
                <a 
                  class="dropdown-item text-wrap"  
                  href="javascript:void(0)" 
                  :class="{'active': statusList.find(a => a.id === status.id)}" 
                  @click="toggleStatus(status)"
                >
                  <font-awesome-icon icon="fa-regular fa-square-check" v-if="statusList.find(a => a.id === status.id)"/>
                  <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="!statusList.find(a => a.id === status.id)" />
                  <span class="ms-2"> 
                    <status-color :color="status.color"></status-color>
                    {{status.name}}
                  </span>
                </a>
              </template>
            
            </div>
          </div>
        </div>
        <div class="mb-4  col-auto">
          <small><label class="m-0">Дата (от)</label></small>
          <input 
            type="date" 
            class="form-control " 
            :placeholder="$t('common.from')"
            v-model="from"
          />
        </div>
        <div class="mb-4  col-auto">
          <small><label class="m-0">Дата (до)</label></small>
          <input 
            type="date" 
            class="form-control " 
            :placeholder="$t('common.till')"
            v-model="till"
          />
        </div>
        <div class="mb-4  col-auto" v-if="regions">
          <small><label class="m-0">Область</label></small>
          <select 
            type="date" 
            class="form-control " 
            
            v-model="selectedRegion"
          >
            <option :value="null">----</option>
            <option v-for="(i, idex) in regions" :value="i" :key="idex">{{ i }}</option>
          </select>
        </div>
        <div class="mb-4  col-auto">
          <small><label class="m-0">&nbsp;</label></small>
          <button class="btn btn-primary" @click="load">
            Завантажити 
          </button>
        </div>
      </div>
      <div class="row">
        
        <div class="col-lg-12"  v-if="report">
          <strong class="p-2 bg-light d-block">Допомога [{{ report.report1.usedCount }}]</strong>
          <h6 class="mb-2 mt-2"> <a :href="fileUrl('reports/files/' + report.report1.qtyFileName)">[Завантажити EXCEL файл]</a><br/></h6>
         
          <!-- <div class="table-responsive" v-if="report">
            <table class="table table-bordered mb-0 table-sm">
             
              <tbody>
                <tr>
                  <td>
                    
                  </td>
                  <template v-for="(b, bi) in report.report1.regions" :key="bi">
                    <td class="vert-text"><span>{{b}}</span></td>
                  </template>
                </tr>
                <tr v-for="(p, pi) in report.report1.productTypes" :key="pi">
                  <th><b>{{p}}</b></th>
                  <template v-for="(b, bi) in report.report1.regions" :key="bi">
                    <td class="text-end">{{report.report1.qty[pi][bi]}}</td>
                  </template>
                </tr>
                <tr class="table-success">
                  <td>
                 
                  </td>
                  <template v-for="(b, bi) in report.report1.regions" :key="bi">
                    <td class="text-right"><b>{{report.report1.countByRegions[b]}}</b></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>  -->
        </div>
        <div class="col-lg-12 mt-4"  v-if="report">
          <strong class="p-2 bg-light d-block">Допомога, сумма [{{ report.report2.usedCount }}]</strong>
          <h6 class="mb-2 mt-2"> <a :href="fileUrl('reports/files/' + report.report2.qtyFileName)">[Завантажити EXCEL файл]</a><br/></h6>
          <!-- <div class="table-responsive" v-if="report">
            <table class="table table-bordered mb-0 table-sm">
             
              <tbody>
                <tr>
                  <td>
                    
                  </td>
                  <template v-for="(b, bi) in report.report2.regions" :key="bi">
                      <td class="vert-text"><span>{{b}}</span></td>
                    </template>
                </tr>
                <tr v-for="(p, pi) in report.report2.productTypes" :key="pi">
                  <th><b>{{p}}</b></th>
                  <template v-for="(b, bi) in report.report2.regions" :key="bi">
                    <td class="text-end">{{report.report2.qty[pi][bi]}}</td>
                  </template>
                </tr>
                <tr class="table-success">
                 <td>
                   
                  </td>
                  <template v-for="(b, bi) in report.report2.regions" :key="bi">
                    <td class="text-right"><b>{{report.report2.countByRegions[b]}}</b></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>  -->
        </div>
        <div class="col-lg-12 mt-4"  v-if="report">
          <strong class="p-2 bg-light d-block">Люди [{{ report.report1.usedCount }}]</strong>
          <h6 class="mb-2 mt-2"> <a :href="fileUrl('reports/files/' + report.report1.peopleQtyFileName)">[Завантажити EXCEL файл]</a><br/></h6>
          <!-- <div class="table-responsive" v-if="report">
            <table class="table table-bordered mb-0 table-sm">
             
              <tbody>
                <tr>
                  <td>
                    
                  </td>
                  <template v-for="(b, bi) in report.report1.regions" :key="bi">
                      <td class="vert-text"><span>{{b}}</span></td>
                    </template>
                </tr>
                <tr v-for="(p, pi) in report.report1.productTypes" :key="pi">
                  <th><b>{{p}}</b></th>
                  <template v-for="(b, bi) in report.report1.regions" :key="bi">
                    <td class="text-end">{{report.report1.peopleQty[pi][bi]}}</td>
                  </template>
                </tr>
                <tr class="table-success">
                  <td>
                   
                  </td>
                  <template v-for="(b, bi) in report.report1.regions" :key="bi">
                    <td class="text-right"><b>{{report.report1.countByRegions[b]}}</b></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>  -->
        </div>

        <div class="col-lg-12 mt-4"  v-if="report">
          <strong class="p-2 bg-light d-block">Бенефіціари [{{ report.report3.usedCount }}]</strong>
          <h6 class="mb-2 mt-2"> <a :href="fileUrl('reports/files/' + report.report3.fileName)">[Завантажити EXCEL файл]</a><br/></h6>
          <!-- <div class="table-responsive" v-if="report">
            <table class="table table-bordered mb-0 table-sm">
             
              <tbody>
                
                <tr v-for="(p, pi) in report.report3.regions" :key="pi">
                  <th><b>{{p}}</b></th>
                  <td>{{report.report3.qty[pi]}}</td>
                  <td class="table-success">
                    <b>{{report.report3.countByRegions[p]}}</b>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>  -->
        </div>
      </div>
      <div class="position-absolute d-flex" v-if="isBusy" style="top:0; bottom:0; left:0; right:0; background-color: rgba(0, 0, 0, 0.3)">
        <span aria-hidden="true" class="m-2 spinner-border text-dark" style="position: absolute; top:45%; left: 50%; right: 50%;"><!----></span>
      </div>
    </div>
   </div>
  
 
</template>
